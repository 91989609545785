/**
 * @fileOverview functions used to make common queries to investorflow server
 * @namespace queries
 * @requires axios
*/
import axios from 'axios';
import { constants } from '../utils/constants';

/**
 * @summary ...
 * @function
 * @memberof queries
 * @description ...
 * @param {object} uniqueFields - ...
 * @param {string} newField - ...
*/
const addNewField = (uniqueFields, newField) => {
  if (newField) {
    const lowerNewField = newField.toLowerCase();
    if (!uniqueFields[lowerNewField]) {
      uniqueFields[lowerNewField] = newField;
    };
  };
};

/**
 * @summary ...
 * @function
 * @memberof queries
 * @description ...
 * @param {object} params - ...
*/
const initializeFieldNames = (params) => {
  const { idField = "Id", labelField, sublabelField, descriptionFields, autoPop } = params;
  const uniqueFields = {};
  addNewField(uniqueFields, idField?.trim());
  addNewField(uniqueFields, labelField?.trim());
  addNewField(uniqueFields, sublabelField?.trim());
  descriptionFields?.forEach(df => {
    addNewField(uniqueFields, df.fieldName?.trim());
  });
  autoPop?.forEach(ap => {
    addNewField(uniqueFields, ap.sourceField?.trim());
  });
  return Object.values(uniqueFields);
};

/**
 * @summary ...
 * @function
 * @memberof queries
 * @description ...
 * @param {string} record - ...
 * @param {array} fields - ...
*/
const getFieldValue = (record, fields) => {
  const valueList = [];
  fields.forEach(fieldName => {
    const fnList = fieldName?.split('.');
    let tempObj = record;
    fnList?.forEach(fn => {
      if (tempObj) {
        tempObj = tempObj[fn];
      }
    });
    if (tempObj) valueList.push(tempObj);
  });
  return valueList.join(". ");
};

/**
 * @summary ...
 * @function
 * @memberof queries
 * @description ...
 * @param {object} params - ...
 * @param {array} records - ...
*/
const getNewOptions = (params, records) => {
  const newOptions = [];
  if (records && records.length > 0) {
    const { idField = "Id", labelField, sublabelField, descriptionFields, autoPop } = params;
    if (!labelField) console.log('missing label field - see config if you need a label for option in', idField);
    const desFields = [];
    descriptionFields?.forEach(df => {
      if (df.fieldName) {
        desFields.push(df.fieldName.trim());
      }
    });
    records.forEach(record => {
      const option = {
        label: getFieldValue(record, [labelField?.trim()]),
        value: record[idField],
      };
      // get sublabel value
      if (sublabelField) {
        option.sublabel = getFieldValue(record, [sublabelField.trim()]);
      }
      // get description value
      option.description = getFieldValue(record, desFields);
      // get auto populate data
      if (autoPop) {
        const autoPopValues = {};
        autoPop.forEach(ap => {
          const sourceValue = getFieldValue(record, [ap.sourceField]);
          if (!!sourceValue) {
            autoPopValues[ap.targetInput] = sourceValue;
          }
        });
        option.autoPop = autoPopValues;
      }
      newOptions.push(option);
    });
  };
  return newOptions;
};

/**
 * @summary base server query function
 * @todo test if this is being used - if not lets delete the function
 * @function
 * @memberof queries
 * @description axios query to investorflow server function that takes in a url and optional options
 * @param {string} url - server url for fetch query
 * @param {object} options - optional options to be used by query
*/
const fetchEntry = async (url, options) => {
  try {
    const headers = { 'Content-Type': 'application/json', 'Accept': 'application/json' };
    const response = await axios.get(url, options ? options : { headers });
    return await response.data;
  } catch (error) {
    console.warn('error fetching source', error);
    return error.response;
  };
};

/**
 * @summary ...
 * @todo test if this is being used - if not lets delete the function
 * @function
 * @memberof queries
 * @description ...
 * @param {string} postUrl - server url for fetch query
 * @param {object} options - optional options to be used by query
 * @param {object} body - body object data to be sent to server
*/
const postEntry = async (postUrl, options, body) => {
  try {
    const response = await fetch(postUrl, { ...options, body: body });
    return await response.text();
  } catch (error) {
    console.warn('error posting', error);
    return { fetchError: error };
  };
};

/**
 * @summary ...
 * @function
 * @memberof queries
 * @description ...
 * @param {object} params - ...
*/
const searchSalesforce = async (params, optionsV3) => {
  const fieldNames = initializeFieldNames(params);
  let searchRecords;
  if (params.enableSOQL) {
    searchRecords = await searchSOQL(params, optionsV3, fieldNames);
  } else {
    searchRecords = await searchSOSL(params, optionsV3, fieldNames);
  };
  return getNewOptions(params, searchRecords);
};

/**
 * @summary ...
 * @function
 * @memberof queries
 * @description ...
 * @param {object} params - ...
 * @param {object} options - ...
 * @param {string} fieldNames - ...
*/
const searchSOQL = async (params, options, fieldNames) => {
  const { userInfo, searchKey, searchFields, filter, objectName, idField = "Id", labelField, orderBy, limit } = params;
  // the logic bellow will also support intializing existing values
  // Constructing a custom filter and removing searchKey from params will support any custom filter
  if (!labelField) console.log('missing label field - please fix config to be able to search');
  const whereClauses = [];
  if (filter) {
    whereClauses.push(filter.replace(/\s+/gi, "+"));
  };
  if (searchKey) {
    // JS needs double escape //
    const escapeSearchKey = searchKey.replace(/\s+/gi, "+").replaceAll("'", "\\'");
    const searchClauses = [];
    if (searchFields && searchFields.length > 0) {
      searchFields.forEach(sf => {
        if (sf.fieldName) {
          searchClauses.push(`${sf.fieldName.trim()}+LIKE+'%${escapeSearchKey}%'`);
        };
      });
    } else {
      searchClauses.push(`${labelField.trim()}+LIKE+'%${escapeSearchKey}%'`);
    };
    whereClauses.push(`(${searchClauses.join("+OR+")})`);
  };
  let orderByClause = "ORDER+BY+";
  if (orderBy) {
    orderByClause += orderBy.replace(/\s+/gi, "+");
  } else {
    orderByClause += labelField + "," + idField;
  };

  const soqlString = `SELECT+${fieldNames}+FROM+${objectName}`
    + `${whereClauses.length > 0 ? "+WHERE+" + whereClauses.join("+AND+") : ""}+`
    + orderByClause + `${limit ? "+LIMIT+" + limit : ""}`;
  const url = `${constants.REACT_SERVER_URL}/api/v3/query/soql/${userInfo?.account}/${userInfo?.instance}?q=${soqlString}`;
  try {
    const response = await axios.get(encodeURI(url), options);
    // console.log('response', response);
    return response?.data?.records || [];
  } catch (error) {
    // 429 error indicates too many request
    // 418 is either a malformed query or invalid session id (need refresh token)
    console.warn('error fetching searchSOQL data', error);
    return [];
  };

};

/**
 * @summary ...
 * @function
 * @memberof queries
 * @description ...
 * @param {object} params - ...
 * @param {object} options - ...
 * @param {string} fieldNames - ...
*/
const searchSOSL = async (params, options, fieldNames) => {
  const { userInfo, searchKey, filter, objectName, idField = "Id", labelField, orderBy, limit } = params;
  const whereClause = filter ? '+WHERE+' + filter.replace(/\s+/gi, "+") : '';
  const soslString = `FIND+{${searchKey.replace(/\s+/gi, "+").replaceAll("'", "\\'")}}+IN+NAME+FIELDS`
    + `+RETURNING+${objectName}(${fieldNames}${whereClause})`
    + `${limit ? "+LIMIT+" + limit : "5"}`;
  const url = `${constants.REACT_SERVER_URL}/api/v3/query/search/${userInfo?.account}/${userInfo?.instance}?q=${soslString}`;
  try {
    const response = await axios.get(encodeURI(url), options);
    return response?.data?.searchRecords;
  } catch (error) {
    console.warn('error fetching searchSOSL data', error);
    return null;
  };
};

export { fetchEntry, postEntry, searchSalesforce, searchSOSL, searchSOQL };
