/**
 * @fileOverview builds attachment pannel used in intro screen
 * @module components/AttachmentsPanel
 * @requires axios
 * @requires mui
*/
import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { PageContext } from '../../context/context';
import { DmlContext } from '../../context/dmlContext';
import { constants } from '../../utils/constants';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, Stack, Typography, } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import AttachmentIcon from '@mui/icons-material/Attachment';
import CircularProgress from '@mui/material/CircularProgress';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import fileIconsMap from '../../utils/maps/fileIconsMap';

import styles from './Attachments.module.css';

/**
 * @summary creates a attachments pannel
 * @description create attachments pannel for email and meetings file attachments attachments
 * @function
 * @memberof module:components/AttachmentsPanel
 * @returns {component}
*/
const AttachmentsPanel = () => {
  const [attachmentList, setAttachmentList] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showSelectAll, setShowSelectAll] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const { interactionId, logError, payload, salesforceUser, selectAttachmentByDefault, themeColors } = useContext(PageContext);
  const { saveContentVersions, setUnsavedAttachments } = useContext(DmlContext);

  useEffect(() => {
    if (payload && salesforceUser) {
      const attList = [];
      if (constants.EMAIL_CLIENT === 'outlook') {
        payload.attachments?.forEach((att) => {
          if (!!att.name && !!att.id) {
            const fileType = getExtension(att?.name);
            const fileIcon = fileIconsMap[fileType] ? fileIconsMap[fileType] : fileIconsMap.unknown;
            attList.push({
              googleId: att.id,
              fileName: att.name.replaceAll('+', '_'),
              icon: fileIcon,
              mimeType: att.contentType,
              selected: false
            });
          }
        });
      } else {
        payload.parts?.forEach((att) => {
          if (!!att.filename && !!att.body?.attachmentId) {
            const fileType = att.filename.split(".").pop();
            const fileIcon = fileIconsMap[fileType] ? fileIconsMap[fileType] : fileIconsMap.unknown;
            attList.push({
              googleId: att.body.attachmentId,
              fileName: att.filename.replaceAll('+', '_'),
              icon: fileIcon,
              mimeType: att.mimeType,
              selected: false
            });
          }
        });
      }

      if (interactionId) {
        getExistingAttachments(attList);
      } else {
        const unsavedList = [];
        attList.forEach(att => {
          att.selected = selectAttachmentByDefault;
          if (att.selected) {
            unsavedList.push(att);
          }
        });
        setUnsavedAttachments(unsavedList);
        setAttachmentList(attList);
        setShowSelectAll(attList.length > 1);
        setShowSaveButton(false);
      }
    }
  }, [interactionId, payload, salesforceUser]);

  const getExtension = (filename) => {
    return filename.split('.').pop();
  };

  const getExistingAttachments = async (attList) => {
    setIsLoading(true);
    const fileNames = [];
    attList.forEach(att => {
      fileNames.push(att.fileName);
    });
    let soqlString = "SELECT+ContentDocumentId,FirstPublishLocationId,PathOnClient,Title+FROM+ContentVersion"
      + "+WHERE+PathOnClient+IN+('" + fileNames.join("','") + "')"
      + "+AND+FirstPublishLocationId+=+'" + interactionId + "'";
    const url = `${constants.REACT_SERVER_URL}/api/v3/query/soql/${salesforceUser?.account}/${salesforceUser?.instance}?q=${soqlString}`;
    const options = { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'x-token': salesforceUser?.token, 'x-refresh': salesforceUser?.refresh, 'x-iv': salesforceUser?.iv } };
    let response;

    try {
      response = await axios.get(encodeURI(url), options);
    } catch (error) {
      logError({
        functionName: 'getExistingAttachments',
        error: error,
        additionalNotes: '',
        displayedToUser: false,
        level: 'error'
      });
      console.warn('error fetching attachment data', error);
    };

    if (response?.data) {
      const existingMap = {};
      response.data.records.forEach(record => {
        existingMap[record.PathOnClient] = record;
      });

      let unsavedCount = 0;
      attList.forEach(att => {
        if (existingMap[att.fileName]) {
          att.saved = true;
          att.docId = existingMap[att.fileName].ContentDocumentId;
          att.fileTitle = existingMap[att.fileName].Title;
        } else {
          unsavedCount++;
        }
      });
      setUnsavedAttachments(null);
      setAttachmentList(attList);
      setShowSelectAll(unsavedCount > 1);
      setShowSaveButton(unsavedCount > 0);
    }

    setIsLoading(false);
  };

  const handleFileUpload = async () => {
    setIsLoading(true);
    await saveContentVersions();
    getExistingAttachments(attachmentList);
  };

  const handleSelectAll = (e) => {
    const newUnsaved = [];
    const newList = [...attachmentList];
    newList.forEach(att => {
      if (!att.saved) {
        att.selected = true;
        newUnsaved.push(att);
      }
    });
    setUnsavedAttachments(newUnsaved);
    setAttachmentList(newList);
  };

  const handleUnselectAll = (e) => {
    setUnsavedAttachments(null);
    const newList = [...attachmentList];
    newList.forEach(att => {
      if (!att.saved) {
        att.selected = false;
      }
    });
    setAttachmentList(newList);
  };

  const toggleItem = (index) => {
    const newList = [...attachmentList];
    newList[index].selected = !newList[index].selected;
    const newUnsaved = [];
    newList.forEach(att => {
      if (att.selected) {
        newUnsaved.push(att);
      }
    });
    setUnsavedAttachments(newUnsaved);
    setAttachmentList(newList);
  };

  return (
    <section className={styles.attachments}>
      <Accordion defaultExpanded disableGutters
        sx={{
          border: `1px solid ${themeColors.tertiary || "rgba(0, 0, 0, 0.2)"}`,
          borderRadius: "0!important",
          boxShadow: "none!important",
        }}>
        <AccordionSummary
          expandIcon={<ArrowDropUpIcon sx={{ color: themeColors.primary }} />}
          sx={{ padding: "0 0.5rem" }}>
          <div style={{ display: "flex" }}>
            <AttachmentIcon sx={{ marginRight: "0.5rem", fill: themeColors.tertiary || "#37474f" }} />
            <Typography
              component="h2"
              sx={{
                margin: "auto",
                fontWeight: "bold",
                fontSize: "0.875rem",
                color: "#37474f",
                lineHeight: "1rem",
              }}>
              Attachments
            </Typography>
            {isLoading &&
              <CircularProgress size={20} sx={{ marginLeft: "1rem" }} />
            }
          </div>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: "0.5rem 0 1rem 0",
            borderTop: "1px solid rgba(0, 0, 0, 0.2)"
          }}>
          {attachmentList && attachmentList.length > 0 ?
            <div className="p-left_large" style={{ fontSize: "0.725rem" }}>
              {showSelectAll &&
                <div className="p-bottom_small grid_end size_1-1" style={{ fontSize: "0.65rem" }}>
                  <button className={styles.unselectAllButton} onClick={handleUnselectAll}>Unselect All</button>
                  <button className={styles.selectAllButton} onClick={handleSelectAll}>Select All</button>
                </div>
              }

              <Stack spacing={1}>
                {attachmentList.map((att, index) =>
                  <div key={`${index}-att`} className="grid size_1-1 p-top_xx-small" style={{ position: "relative" }}>

                    <Box component="img"
                      src={att.icon}
                      sx={{
                        height: "26px",
                        marginRight: "0.75rem",
                        opacity: att.saved ? "1" : "0.4"
                      }} />

                    <div style={{ lineBreak: "anywhere", width: `calc(100% - ${att.saved ? "46px" : "60px"})` }}>
                      {att.saved ?
                        <a href={`${salesforceUser?.url}/${att.docId}`} target={'_blank'} rel="noreferrer"><b style={{ color: "#1976d2" }}>{att.fileTitle}</b></a>
                        :
                        att.fileName
                      }
                    </div>

                    {!att.saved &&
                      <div className={styles.checkboxContainer}>
                        <Checkbox size="medium" style={{ color: themeColors.secondary }} checked={att.selected} onChange={(e) => toggleItem(index)} />
                      </div>
                    }
                  </div>
                )}
              </Stack>
              {showSaveButton &&
                <div className="size_1-1 p-top_small grid_reverse">
                  <Button size="small" color="primary" variant="contained"
                    sx={{ marginRight: "0.5rem" }}
                    disabled={isLoading}
                    startIcon={<FileUploadIcon />}
                    onClick={handleFileUpload}>
                    Save
                  </Button>
                </div>
              }
            </div>
            :
            <div className='grid_center' style={{ fontSize: "0.725rem" }}><i>No attachment found</i></div>
          }
        </AccordionDetails>
      </Accordion >
    </section >
  );
};

export default AttachmentsPanel;
