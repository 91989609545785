import React, { useContext, useEffect, useState } from 'react';
import { PageContext } from '../../context/context';
import { Autocomplete, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
/**
 * InputPicklist is a dynamic component that renders either a standard dropdown or a multi-select autocomplete input,
 * depending on configuration. It's designed to handle both single and multiple selections for options provided
 * through configuration, with support for conditional display based on control fields and record type.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.config - Configuration for the picklist, including options, default values, and other attributes.
 * @param {string} props.id - Unique identifier for the picklist input, used for managing form control and accessibility.
 * @param {string} props.label - Label for the input, displayed above the picklist.
 * @param {string} props.sectionId - Identifier for the form section that this input belongs to, used for managing state in context.
 * @param {Function} props.onChange - Callback function triggered when the input value changes, handling data processing and storage.
 * @returns {JSX.Element} A component that either renders a Select or Autocomplete input based on the `multiple` attribute.
 *
 * @description
 * This component integrates with a global context (`PageContext`) to manage and store the value of the picklist input across different uses.
 * It supports:
 * - Single and multiple selections with immediate feedback on user interaction.
 * - Dynamic loading and filtering of options based on associated record types and control field values.
 * - Initial default values and real-time updates through a controlled component approach.
 *
 * Features include:
 * - A rich set of options dynamically rendered based on external conditions such as record type id or other field values.
 * - Compatibility with both single and multiple select paradigms, adjusting its UI and interaction model accordingly.
 * - Debounced input handling to optimize performance and reduce update frequency.
 *
 * The component is particularly useful in forms requiring user input selection from a predefined list of options, providing users
 * with a flexible and user-friendly interface for selection.
 */

const InputPicklist = (props) => {
  // console.log('in InputPicklist', props);
  const { config, id, label, sectionId, onChange } = props || {};
  const { attributes, controlField, controlValues, defaultValue, recordTypeId } = config || {};
  const { multiple, required, variant } = attributes || {};
  const { handleOnChangeWithDetail, valueStorage } = useContext(PageContext);

  const [internalValue, setInternalValue] = useState(multiple ? [] : '');

  /* START initialize options */
  const [options, setOptions] = useState(() => {
    const newOptions = [];
    attributes.options?.forEach(o => {
      if (typeof o === "string") {
        newOptions.push({ label: o, value: o });
      } else {
        newOptions.push(o);
      };
    });
    return newOptions;
  });

  useEffect(() => {
    if (config?.targetField?.toLowerCase().indexOf('recordtypeid') >= 0) return;

    let filteredRecordType = [];
    if (recordTypeId && attributes.options) {
      attributes?.options?.forEach(o => {
        if (!o.ctrlValues || o.ctrlValues.indexOf(recordTypeId) >= 0 || !o.value) {
          filteredRecordType.push(o);
        }
      });
    } else {
      filteredRecordType = [...attributes?.options];
    }
    let filteredControlValues = [];
    if (controlField) {
      if (controlValues?.length > 0) {
        filteredRecordType.forEach(o => {
          if (o?.value) {
            if (o?.ctrlValues) {
              controlValues.some(val => {
                if (o.ctrlValues.indexOf(val) >= 0) {
                  filteredControlValues.push(o);
                  return true;
                }
                return false;
              });
            }
          } else {
            filteredControlValues.push(o);
          }
        });
      } else {
        filteredRecordType.forEach(o => {
          if (!o.value || !o.ctrlValues || o.ctrlValues.length === 0) {
            filteredControlValues.push(o);
          }
        });
      }
    } else {
      filteredControlValues = [...filteredRecordType];
    }
    setOptions(filteredControlValues);
  }, [controlValues, recordTypeId]);
  /* END initialize options */

  /* START Internal Value */
  useEffect(() => {
    if (valueStorage?.[sectionId]?.[id]) {
      // console.log('valueStorage', valueStorage)
      const newValue = valueStorage[sectionId][id].value;
      if (newValue !== internalValue) {
        if (multiple) {
          if (typeof newValue === 'string') {
            let multiValue = [];
            newValue.split(';').forEach(val => {
              multiValue.push({ label: val, value: val });
            });
            setInternalValue(multiValue);
          } else if (newValue) {
            setInternalValue(newValue);
          } else {
            setInternalValue([]);
          };
        } else {
          setInternalValue(newValue);
        };
      };
    } else {
      let newValue = multiple ? [] : null;
      if (defaultValue) {
        if (multiple) {
          if (typeof defaultValue === 'string') {
            newValue = [];
            defaultValue.split(';').forEach(val => {
              newValue.push({ label: val, value: val });
            });
          } else {
            newValue = defaultValue;
          };
        } else {
          newValue = defaultValue;
        };
      };
      handleOnChange(null, null, null, null, newValue);
    };
  }, [valueStorage, sectionId, id, multiple]);
  /* END Internal Value */

  const handleOnChange = (event, newValue, field, valueOption, defaultValue) => {
    const val = multiple ? newValue : event?.target?.value;
    const detail = {
      inputId: id,
      sectionId: sectionId,
      targetField: config?.targetField,
      type: config?.type,
      multiple: multiple,
      value: defaultValue ? defaultValue : val
    };
    if (typeof onChange === 'function') {
      setInternalValue(val ? val : defaultValue);
      onChange(detail);
    } else {
      handleOnChangeWithDetail(detail);
    };
  };

  let inputNode = <div></div>;
  if (multiple) {
    inputNode =
      <Autocomplete fullWidth multiple disableClearable
        options={options}
        value={internalValue}
        filterSelectedOptions
        isOptionEqualToValue={(o, v) => o.value === v.value}
        size="small"
        onChange={handleOnChange}
        renderInput={(params) =>
          <TextField required={required} {...params} label={label} variant={variant} />
        }
      />
  } else {
    const optionNodes = [];
    options.forEach((o, i) => {
      optionNodes.push(<MenuItem key={`${i}-option`} value={o.value}>{o.label}</MenuItem>)
    });

    inputNode =
      <FormControl fullWidth size="small" variant={variant}>
        <InputLabel required={required} id={id + "-label"}>{label}</InputLabel>
        <Select
          labelId={id + "-label"}
          id={id}
          value={internalValue ? internalValue : ''}
          label={label}
          onChange={handleOnChange}
        >
          {optionNodes}
        </Select>
      </FormControl>
      ;
  }

  return (
    <>
      {inputNode}
    </>
  );
};

export default InputPicklist;
