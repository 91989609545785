import { useContext } from "react";
import { PageContext } from '../../context/context';
import { ClickAwayListener, MenuItem, MenuList, ListItemText, ListItemIcon } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import SettingsIcon from '@mui/icons-material/Settings';


/**
 * @summary creates user menu
 * @description Builds out the user menu used in the user modal. currently
 * @memberof module:components/User
 * @function
 * @returns {component}
*/
const UserMenu = () => {
  const { salesforceUser, setIsLoginScreenOpen, setShowUserMenu, setScreenType } = useContext(PageContext);

  const handleOnClick = () => {
    setScreenType("intro");
    setIsLoginScreenOpen(true);
    setShowUserMenu(false);
  };

  const handleInfoOnClick = () => {
    setScreenType("debug");
    setShowUserMenu(false);
  };

  return (
    <ClickAwayListener onClickAway={() => { setShowUserMenu(false) }}>
      <MenuList sx={{ minWidth: "180px" }}>
        <button className={'hidden-btn'} onClick={() => { setShowUserMenu(false) }}>Close</button>
        <MenuItem autoFocus={true} tabIndex={0} component={'button'} dense onClick={handleOnClick} sx={{ width: "100%" }}>
          <ListItemIcon sx={{ minWidth: "26px!important" }}>
            <SettingsIcon size="small" sx={{ fontSize: "1.5rem!important" }} />
          </ListItemIcon>
          <ListItemText sx={{ display: "flex" }}>Connections</ListItemText>
        </MenuItem>
        { salesforceUser?.debugMode &&
          <MenuItem component={'button'} tabIndex={0} dense onClick={handleInfoOnClick} sx={{ width: "100%" }}>
            <ListItemIcon sx={{ minWidth: "26px!important" }}>
              <InfoIcon size="small" sx={{ fontSize: "1.5rem!important" }} />
              </ListItemIcon>
            <ListItemText sx={{ display: "flex" }}>Information Panel</ListItemText>
          </MenuItem>
        }
      </MenuList>
    </ClickAwayListener>
  );
};

export default UserMenu;
