import { useContext, useState, useEffect } from 'react';
import { PageContext } from '../../context/context';
import { getTruncatedText } from '../../utils/helperFunctions';
import { InputLabel, TextareaAutosize, FormControl } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { primaryTheme } from '../../utils/theme';

/**
 * InputTextArea is a component that renders a text area input with auto-resizing capabilities. It is designed to integrate with a context that
 * provides theme colors and stores user input values, facilitating data entry and management in forms across the application.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.config - Configuration for the text area, including attributes such as max length and the variant.
 * @param {string} props.id - The unique identifier for the text area, used for accessibility and form control.
 * @param {string} props.label - The label text for the text area.
 * @param {string} props.sectionId - The identifier for the section this text area belongs to, used in managing stored values.
 * @param {Function} props.onChange - The callback function that triggers on user input, intended to handle the updated value.
 * @returns {JSX.Element} A FormControl component containing a TextareaAutosize input.
 *
 * @description
 * This component is responsible for rendering a text area with dynamic styling based on the theme provided by the PageContext.
 * It supports input attributes such as `maxLength` and adjusts its appearance based on the `variant` specified in its configuration.
 * The component initializes its internal value based on the `valueStorage` provided by the context, ensuring that any externally
 * managed changes are reflected. User inputs are debounced to prevent excessive updates during typing, which is managed using
 * a combination of useState and useEffect hooks to handle state and side effects.
 *
 * Styling is applied conditionally based on the variant of the text area, supporting both 'standard' and other unspecified styles
 * by altering the label's CSS properties dynamically. The TextareaAutosize component allows for a flexible text input experience,
 * adjusting its size based on the content.
 */
const InputTextArea = (props) => {
  // console.log('from Input Text area', props);
  const { config, id, label, sectionId, onChange } = props || {};
  const { color, required, variant } = config?.attributes || {};
  const { handleOnChangeWithDetail, themeColors, valueStorage } = useContext(PageContext);
  const [onchangeTimeout, setOnchangeTimeout] = useState(null); //This is needed otherwise clearTimeout wont work

  /* START Internal Value */
  const [internalValue, setInternalValue] = useState('');
  useEffect(() => {
    if (valueStorage?.[sectionId]?.[id]) {
      const newValue = getTruncatedText(valueStorage[sectionId][id].value, config);
      if (newValue !== internalValue) {
        setInternalValue(newValue);
      }
    }
  }, [valueStorage, sectionId, id]);
  /* END Internal Value */

  const handleOnChange = (e) => {
    if (onchangeTimeout) clearTimeout(onchangeTimeout);
    setOnchangeTimeout(setTimeout(() => {
      const detail = {
        inputId: id,
        sectionId: sectionId,
        targetField: config?.targetField,
        type: config?.type,
        value: e.target.value
      };
      if (typeof onChange === 'function') {
        onChange(detail);
      } else {
        handleOnChangeWithDetail(detail);
      };
    }, 500));

    setInternalValue(e.target.value);
  };

  const labelStyle = {
    fontSize: ".75rem",
    lineHeight: "1.4375rem",
    lineSpacing: "0.00938rem",
    padding: "0 0.25rem 0 0",
    maxWidth: "calc(133% - 24px)",
    userSelect: "none"
  };
  if (variant === 'standard') {
    labelStyle.position = "relative";
    labelStyle.transform = "none";
  } else {
    labelStyle.left = 0;
    labelStyle.top = 0;
    labelStyle.display = "block";
    labelStyle.transformOrigin = "left top";
    labelStyle.position = "absolute";
    labelStyle.backgroundColor = "white";
    labelStyle.transform = "translate(0, -7px)";
    labelStyle.zIndex = 1;
  };


  return (
    <div className="input-textarea">
      <ThemeProvider theme={primaryTheme(color || themeColors.primary)}>
        <FormControl fullWidth>
          <InputLabel htmlFor={id + "-textarea"} required={required} sx={labelStyle}> {label} </InputLabel>
          <TextareaAutosize
            autoFocus={props?.autoFocus}
            className="border border-radius"
            id={id + "-textarea"}
            maxLength={config?.attributes?.maxLength}
            minRows={5}
            onChange={handleOnChange}
            style={{
              maxHeight: "20rem",
              overflow: "auto!important",
              width: "calc(100% - 18px)",
              maxWidth: "calc(100% - 18px)",
              fontSize: "0.8125rem",
              fontFamily: "Roboto, Helvetica, Arial, sans-serif",
              padding: ".5rem"
            }}
            value={internalValue}
          />
        </FormControl>
      </ThemeProvider>
    </div>
  );
};

export default InputTextArea;
