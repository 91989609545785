import { useContext, useEffect, useId, useState } from 'react';
import { PageContext } from '../context/context';
import { constants } from '../utils/constants';
import { Button } from "@mui/material";
import { isOfficeSetSupported } from '../utils/helperFunctions';
import Cookies from "js-cookie";
import CustomAvatar from '../components/User/CustomAvatar';
import sanitizeHtml from 'sanitize-html';
import SectionCmp from "../components/Sections/SectionCmp";

import styles from './templates.module.css';


/**
 * @summary Debug Panel Template
 * @interface DebugPanelTemplate
 * @description Screen for showing debug information
 */
const DebugPanelTemplate = () => {
  const [debugItems, setDebugItems] = useState();
  const {
    detectedEntities, errorLogs, googleAuthUser, msAuthUser, payload, salesforceUser, setUserMenuAnchorEl, setScreenType, setShowUserMenu, showUserMenu, themeColors
  } = useContext(PageContext);
  const user = msAuthUser ? msAuthUser?.email?.substr(0, 1) : googleAuthUser?.email?.substr(0, 1);
  const userMenuCmpId = useId();
  const isOutlook = constants.EMAIL_CLIENT === 'outlook' ? true : false;

  const getHighestNameSupported = () => {
    if (isOfficeSetSupported('1.15')) return '1.15';
    if (isOfficeSetSupported('1.14')) return '1.14';
    if (isOfficeSetSupported('1.13')) return '1.13';
    if (isOfficeSetSupported('1.12')) return '1.12';
    if (isOfficeSetSupported('1.11')) return '1.11';
    if (isOfficeSetSupported('1.10')) return '1.10';
    if (isOfficeSetSupported('1.9')) return '1.9';
    if (isOfficeSetSupported('1.8')) return '1.8';
    if (isOfficeSetSupported('1.7')) return '1.7';
    if (isOfficeSetSupported('1.6')) return '1.6';
    if (isOfficeSetSupported('1.5')) return '1.5';
    if (isOfficeSetSupported('1.4')) return '1.4';
    if (isOfficeSetSupported('1.3')) return '1.3';
    if (isOfficeSetSupported('1.2')) return '1.2';
    if (isOfficeSetSupported('1.1')) return '1.1';
    return 'ERROR Office Api not Supported';
  };

  const browserInfo = {
    browserName: navigator.appVersion,
    cookieEnabled: navigator.cookieEnabled,
    agentInformation: navigator.userAgent,
    osPlatform: navigator.platform,
    browserVendor: navigator.vendor,
  };

  if (isOutlook) {
    browserInfo.officeHost = Office.context.diagnostics.host;
    browserInfo.officePlatform = Office.context.diagnostics.platform;
    browserInfo.officeVersion = Office.context.diagnostics.version;
    browserInfo.hostVersion = Office.context.mailbox.diagnostics.hostVersion;
    browserInfo.highestSupportedApiSet = getHighestNameSupported();
    browserInfo.manifestVersion = process.env.REACT_APP_VERSION;
  } else {
    browserInfo.manifestVersion = chrome?.runtime?.getManifest()?.version;
  };

  useEffect(() => {
    setDebugItems(
      [
        {
          iconName: 'company',
          id: "browser-info",
          inputFields: [
            {
              id: "browser-info-table",
              rows: createData(browserInfo),
              type: "table",
            }
          ],
          label: "Browser Info",
          table: true
        },
        {
          iconName: 'info',
          id: "sf-user-info",
          inputFields: [
            {
              id: "sf-user-info-table",
              rows: createData(salesforceUser),
              type: "table",
            }
          ],
          label: "SalesForce User Info",
          table: true
        },
        {
          iconName: 'contacts',
          id: "email-user-info",
          inputFields: [
            {
              id: "email-user-info-table",
              rows: createData(googleAuthUser ? googleAuthUser : msAuthUser),
              type: "table",
            }
          ],
          label: `${googleAuthUser ? 'Google' : 'Outlook'} User Info`,
          table: true
        },
        {
          iconName: 'email',
          id: "interaction-info",
          inputFields: [
            {
              id: "interaction-info-table",
              rows: createData(payload),
              type: "table",
            }
          ],
          label: `Interaction Item`,
          table: true
        },
        {
          iconName: 'body',
          id: "entity-recognition-data",
          inputFields: [
            {
              id: "entity-recognition-data-table",
              rows: createData(detectedEntities),
              type: "table",
            }
          ],
          label: `Entity Recognition`,
          table: true
        },
        {
          iconName: 'error',
          id: "error-logs",
          inputFields: [
            {
              id: "error-logs-table",
              rows: (errorLogs && errorLogs.length > 0 )? createLogData(errorLogs) : [],
              type: "table",
            }
          ],
          label: "Info Logs",
          table: true
        }
      ]
    );
  }, [detectedEntities, msAuthUser, payload]);

  const createData = (itemList) => {
    let parsedList = [];
    if (!itemList) return parsedList;
    for (const [key, value] of Object.entries(itemList)) {
      if (typeof value !== "function" && !Array.isArray(value)) {
        parsedList.push( { label: key, value: JSON.stringify(value) } );
      } else if (Array.isArray(value)) {
        let nestedList = [];
        value.forEach(item => {
          for (const [nestedKey, nestedValue] of Object.entries(item)) {
            nestedList.push({label: nestedKey, value: JSON.stringify(nestedValue)})
          }
        })
        parsedList.push( { label: key, value: nestedList } )
      };
    };
    return parsedList;
  };

  // Ensure that rows is an array of objects with 'label' and 'value' keys
  const createLogData = (logErrors) => {
    const list = [];
    logErrors?.map((log, index) => {
      const formattedDate = new Date(log?.timestamp).toLocaleString('en-US', {
        weekday: 'short', // "Mon" through "Sun"
        year: 'numeric', // 2021
        month: 'short', // "Jan" through "Dec"
        day: 'numeric', // 1 through 31
        hour: '2-digit', // 01 through 12 AM/PM
        minute: '2-digit', // 00 through 59
        second: '2-digit' // 00 through 59
      });

      list.push(
        {
          id: `${index}-${formattedDate}`,
          label: `${formattedDate} - ${log?.functionName}`,
          level: log?.level,
          value: `Message: ${log?.errorMessage} - Additional Notes: ${log?.additionalNotes} - Displayed to User: ${log?.displayedToUser ? 'Yes' : 'No'}`
        }
      );
    });
    return list;
  };

  const deleteCache = () => {
    window.sessionStorage.removeItem(constants.ENTITY_RECOGNITION_STORAGE);
  };

  // clearing the cookies before signing out will allow changing users in same org
  const eatTheCookies = () => {
    if (isOutlook) {
      localStorage.removeItem("_investorflow");
    } else {
      chrome.storage.session.remove(["investorflow"]).then(() => {
        chrome.storage.local.remove(["investorflow"]);
      });
    };
    const host = window.location.hostname;
    Cookies.remove('_investorflow', { domain: host, path: '/' });
  };

  const handleCopy = () => {
    const textToCopy = [
      { browserInfo: browserInfo },
      { salesforceUser: salesforceUser },
      { emailUser: googleAuthUser ? googleAuthUser : msAuthUser },
      { emailData: payload },
      { entityRecognition: detectedEntities },
    ];

    navigator.permissions.query({ name: "clipboard-write" }).then((result) => {
      const detailsValue = JSON.stringify(textToCopy, null, " ");
      if (result.state === "granted" || result.state === "prompt") {
        navigator.clipboard.writeText(detailsValue);
      } else {
        return new Promise(function (resolve, reject) {
          try {
            const textArea = document.createElement('textarea');
            textArea.value = sanitizeHtml(detailsValue);
            document.body.appendChild(textArea);
            textArea.select();
            document.execCommand('copy');
            textArea.remove();
            resolve();
          } catch (e) {
            reject(e);
          };
        });
      };
    });
  };

  const ReturnToIntroButton = () => {
    return (
      <Button
        className={styles.closeSignInButton}
        onClick={() => setScreenType("intro")}
        size={'medium'}
        sx={{color:themeColors.primary, borderColor:themeColors.primary, '&:hover': {
          borderColor:themeColors.primary
        }}}
        variant="outlined"
      >
          Close Info Screen
      </Button>
    );
  };

  return (
    <div className={styles.debugPannelTemplate}>
    { isOutlook
      ? <div role="heading" aria-level="1" className={styles.logScreenTitleDebug} style={{ backgroundColor: themeColors.primary }}>
          Information Panel
          <div className={styles.debugAvatar}>
            <CustomAvatar
              id={userMenuCmpId}
              onClick={(event) => {
                setUserMenuAnchorEl(event.currentTarget);
                setShowUserMenu(!showUserMenu);
              }}
              salesforceUser={salesforceUser?.displayName}
              themeColor={themeColors.tertiary}
              user={user}
              />
            </div>
        </div>
      : <div className={styles.logScreenTitle} style={{ backgroundColor: themeColors.primary }}>
          Information Panel
        </div>
    }
      <>
        <ReturnToIntroButton />
        <Button
          className={styles.closeSignInButton}
          onClick={handleCopy}
          size={'medium'}
          sx={{color:themeColors.primary, borderColor:themeColors.primary, '&:hover': {
            borderColor:themeColors.primary
          }}}
          variant="outlined"
        >
          Copy Data
        </Button>

        { debugItems &&
          debugItems.map((item, key) => (
            <SectionCmp key={`${item.id}-${key}-debug`} config={item} />
          ))
        }

        <Button
          className={styles.closeSignInButton}
          onClick={deleteCache}
          size={'medium'}
          sx={{color:themeColors.primary, borderColor:themeColors.primary, '&:hover': {
            borderColor:themeColors.primary
          }}}
          variant="outlined"
        >
          Delete ER Cache
        </Button>

        <Button
          onClick={eatTheCookies}
          size={'medium'}
          sx={{ marginTop: '0.5rem', width: '100%' }}
          variant="contained"
        >
          Delete User Cookie
        </Button>

        <a className={"btn btn_mui m-top_small "} href="javascript:(function(F,i,r,e,b,u,g,L,I,T,E){if(F.getElementById(b))return;E=F[i+'NS']&&F.documentElement.namespaceURI;E=E?F[i+'NS'](E,'script'):F[i]('script');E[r]('id',b);E[r]('src',I+g+T);E[r](b,u);(F[e]('head')[0]||F[e]('body')[0]).appendChild(E);E=new%20Image;E[r]('src',I+L);})(document,'createElement','setAttribute','getElementsByTagName','FirebugLite','4','firebug-lite.js','releases/lite/latest/skin/xp/sprite.png','https://cdnjs.cloudflare.com/ajax/libs/firebug-lite/1.4.0/','#startOpened');">Debug Console</a>

        <ReturnToIntroButton />

        <div className="size_1-2 p-around_x-small" />
      </>
    </div>
  );
};

export default DebugPanelTemplate;
