import { useContext, useEffect, useState } from 'react';
import { PageContext } from '../../context/context';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Button, ClickAwayListener, IconButton, InputAdornment, MenuItem, MenuList, Paper, Popover, TextField } from "@mui/material";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { expansiveTheme, primaryTheme } from '../../utils/theme';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ThemeProvider } from '@mui/material/styles';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import styles from './Inputs.module.css';

/**
 * @summary Input field for Date, Time, and Datetime.
 * @function handleDateChange
 * Parse new datetime value into date value.
 * When time is disabled, date value is parsed differently
 * @function handleTimeChange
 * Handle onchange of the time input field.
 * Calls processTimeInput to parse text value into time.
 * @function openTimeSelection
 * Toggle time selection
 * @function processNewValue
 * Split new datetime value into date and time pieces and store internal values for display
 * @function processTimeInput
 * Parse new input into time value.
 * @function resetInternalValues
 * Reset all internal values
 * @function setCurrentLocalTime
 * Consolidate both date and time value and send to parent.
 * @namespace components/inputs/InputDateTime
 * @description This component renders Date and Time input field. It supports Date, Time, and Datetime.
 * @example
 * <InputDateTime config id label sectionId onChange defaultValue attributes></InputDateTime>
*/

dayjs.extend(utc);
dayjs.extend(timezone);

const InputDateTime = (props) => {
  // console.log('from input date time', props);
  const { config, id, label, sectionId, onChange } = props || {};
  const { attributes, defaultValue } = config || {};
  const { required, variant = "standard" } = attributes || {};
  const type = config?.type || props?.type;

  const { handleOnChangeWithDetail, themeColors, valueStorage } = useContext(PageContext);

  const [timeValueDisplay, setTimeValueDisplay] = useState('');
  const [timeAnchorEl, setTimeAnchorEl] = useState(null);
  const [showTimePicker, setShowTimePicker] = useState(false);
  const [highNoon, setHighNoon] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const enableDate = type !== "time";
  const enableTime = type !== "date";

  const hourOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const minuteOptions = [0, 15, 30, 45];
  const [dateValue, setDateValue] = useState(null);
  const [hourValue, setHourValue] = useState(null);
  const [minuteValue, setMinuteValue] = useState(null);

  /* START Set Time Value */
  useEffect(() => {
    if (hourValue || minuteValue || hourValue === 0 || minuteValue === 0) {
      let hh = "12", mm = "00";
      if (hourValue === 0) {
        hh = "12";
      } else {
        hh = hourValue + "";
      }
      if (minuteValue >= 0 && minuteValue < 10) {
        mm = "0" + minuteValue;
      } else {
        mm = minuteValue + "";
      }
      setTimeValueDisplay(`${hh}:${mm} ${highNoon ? "PM" : "AM"}`);

      if (enableDate) {
        let dtVal = internalValue ? dayjs(internalValue) : dateValue ? dayjs(dateValue) : dayjs();
        dtVal = dtVal.local().set('h', highNoon ? hourValue + 12 : hourValue).set('m', minuteValue);
        if (!dateValue) {
          setDateValue(dtVal.local());
        }
        setInternalValue(dtVal.utc().toISOString());
      } else {
        setInternalValue(`${hh}:${mm}:00.000Z`);
      }
    } else {
      setTimeValueDisplay('');
    }
  }, [hourValue, minuteValue, highNoon]);
  /* END Set Time Value */

  /* START Internal Value */
  const [internalValue, setInternalValue] = useState(null); // Internal Value should always be UTC
  useEffect(() => {
    if (isReady) {
      const detail = {
        inputId: id,
        sectionId: sectionId,
        targetField: config?.targetField,
        type: config?.type,
        value: internalValue
      };
      if (typeof onChange === 'function') {
        onChange(detail);
      } else {
        //console.log(valueStorage?.[sectionId]?.[id]?.value !== internalValue, valueStorage?.[sectionId]?.[id]?.value, internalValue);
        if (valueStorage?.[sectionId]?.[id]?.value !== internalValue) {
          handleOnChangeWithDetail(detail);
        }
      }
    }
  }, [internalValue]);

  useEffect(() => {
    if (defaultValue) {
      processNewValue(defaultValue);
      setIsReady(true);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (valueStorage?.[sectionId]?.[id]) {
      processNewValue(valueStorage[sectionId][id].value);
      setIsReady(true);
    }
  }, [valueStorage, sectionId, id]);

  const processNewValue = (newValue) => {
    // Expecting newValue to always be of type string
    if (newValue) {
      if (newValue !== internalValue) {
        setInternalValue(newValue);
        if (enableDate && enableTime) {
          const newDateValue = dayjs(newValue).local();
          setDateValue(newDateValue);
          processTimeInput(newDateValue.hour() + ":" + newDateValue.minute());
        } else if (enableDate) {
          setDateValue(dayjs(newValue));
        } else if (enableTime) {
          const timeParts = newValue.split(":");
          processTimeInput(timeParts[0] + ":" + timeParts[1]);
        }
      }
    } else {
      resetInternalValues(null);
    }
  };
  /* END Internal Value */

  /* START Date Handler */
  const handleDateChange = (newValue) => {
    setIsReady(true);
    if (newValue) {
      if (enableTime) {
        // Handle changes for datetime field
        if (internalValue) {
          let storeValue = dayjs(internalValue).local();
          storeValue = storeValue.set('y', newValue.get('y')).set('M', newValue.get('M')).set('D', newValue.get('D'));
          setInternalValue(storeValue.utc().toISOString());
        } else {
          setInternalValue(newValue.utc().toISOString());
        }
        setDateValue(newValue.local());
      } else {
        // Handle changes for date field only
        // Date field without time should not use timezone
        setDateValue(dayjs(newValue));
        setInternalValue(newValue.utc().toISOString());
      }
    } else {
      resetInternalValues();
    }
  };
  /* END Date Handler */

  /* START Time Handler */
  const openTimeSelection = (e) => {
    if (!timeAnchorEl) {
      setTimeAnchorEl(e.currentTarget.parentElement.parentElement.parentElement);
    }
    setShowTimePicker(!showTimePicker);
  };

  const [onchangeTimeout, setOnchangeTimeout] = useState(null); //This is needed otherwise clearTimeout wont work
  const handleTimeChange = (e) => {
    if (e.target.value === timeValueDisplay) return;

    if (onchangeTimeout) clearTimeout(onchangeTimeout);
    const newTextValue = e.target.value?.trim();
    if (!!newTextValue) {
      setOnchangeTimeout(setTimeout(() => {
        processTimeInput(newTextValue);
      }, 1000));
      setTimeValueDisplay(e.target.value || '');
    } else {
      setTimeValueDisplay('');
    }
  }

  const processTimeInput = (newTextValue) => {
    const timeParts = newTextValue.split(/\D+/);
    let hourInt = 0, minuteInt = 0, itsHighNoon;
    try {
      hourInt = Number.parseInt(timeParts[0]);
      minuteInt = Number.parseInt(timeParts[1]);
    } catch (e) { }

    if (hourInt >= 0 && hourInt < 24) {
      itsHighNoon = hourInt >= 12;
      setHourValue(hourInt % 12);
    } else {
      setHourValue(0);
    }
    if (minuteInt >= 0 && minuteInt < 60) {
      setMinuteValue(minuteInt);
    } else {
      setMinuteValue(0);
    }
    setHighNoon(itsHighNoon || newTextValue.toLowerCase().indexOf("pm") >= 0);
  };
  /* END Date Handler */

  const setCurrentLocalTime = () => {
    if (enableDate && enableTime) {
      const detail = {
        inputId: id,
        sectionId: sectionId,
        targetField: config?.targetField,
        type: config?.type,
        value: dayjs().utc().toISOString()
      };
      if (typeof onChange === 'function') {
        onChange(detail);
      } else {
        handleOnChangeWithDetail(detail);
      }
    } else if (enableDate) {
      handleDateChange(dayjs());
    } else if (enableTime) {
      const nowTime = dayjs().local();
      processTimeInput(nowTime.hour() + ":" + nowTime.minute());
    }
  };

  const resetInternalValues = () => {
    setDateValue(null);
    setHourValue(null);
    setMinuteValue(null);
    setInternalValue(null);
    setHighNoon(false);
  };

  return (
    <div className={'input__date-time'}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className={styles.datetimeContainer}>
          {enableDate ?
            <div className={styles.datePickerContainer} data-enable-time={enableTime}>
              <DesktopDatePicker
                autoFocus={true}
                className={"date-picker"}
                label={label}
                onChange={handleDateChange}
                PopperProps={{ sx: { marginLeft: "0.5rem!important" } }}
                slotProps={{ textField: { required: required, size: "small", variant: variant, fullWidth: true } }}
                value={dateValue}
              />
            </div>
            : <div></div>
          }
          {enableTime ?
            <div className={styles.timePickerContainer} data-enable-date={enableDate}>
              <TextField fullWidth variant={variant} size="small"
                label={enableDate ? ' ' : label}
                onChange={handleTimeChange}
                value={timeValueDisplay}
                placeholder="hh:mm a"
                required={enableDate ? false : required}
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end">
                      <IconButton size="small" sx={{ margin: "-2px -4px 0px 0px" }} onClick={openTimeSelection}>
                        <AccessTimeIcon />
                      </IconButton>
                    </InputAdornment>
                }}
              />
            </div>
            : <div></div>
          }
          {/* TODO: out of DOM order */}
          <ThemeProvider theme={expansiveTheme('#eeeeee', '#e0e0e0', '#f5f5f5')}>
            <Button variant="contained" color={"primary"} size="small"
              className={styles.todayButton}
              onClick={setCurrentLocalTime}>
              {enableTime ? 'now' : 'today'}
            </Button>
          </ThemeProvider>
        </div>
      </LocalizationProvider>
      <Popover
        open={showTimePicker}
        anchorEl={timeAnchorEl}
        className={styles.timePopover}
        sx={{ transform: enableDate ? "translateX(-1.5rem)" : "" }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <ClickAwayListener onClickAway={() => { setShowTimePicker(false) }}>
          <Paper>
            <ThemeProvider theme={primaryTheme(themeColors.primary)}>
              <div style={{ display: "flex", padding: "0.25rem", margin: "auto" }}>
                <Button variant={highNoon ? "outlined" : "contained"} size="small"
                  sx={{ minWidth: "52px", borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                  onClick={() => setHighNoon(false)}>AM</Button>
                <Button variant={highNoon ? "contained" : "outlined"} size="small"
                  sx={{ minWidth: "52px", borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                  onClick={() => setHighNoon(true)}>PM</Button>
              </div>
            </ThemeProvider>

            <div className="grid_spread" style={{ padding: "0.25rem", margin: "auto" }}>
              <div className="grid_center" style={{ paddingRight: "1rem" }}><b>hh</b></div>
              <div className="grid_center"><b>mm</b></div>
            </div>
            <div className="grid">
              <MenuList sx={{ maxHeight: "128px", overflowY: "auto" }}>
                {hourOptions && hourOptions.map((item, index) => (
                  (item === hourValue) ?
                    <MenuItem key={`${item}-${index}-hr`} dense
                      autoFocus={true} tabIndex={0} component={'button'}
                      className={styles.selectedTimeItem}
                      sx={{ backgroundColor: themeColors.primary }}>
                      {item < 10 ? "0" + item : item}
                    </MenuItem>
                    :
                    <MenuItem key={item} dense
                      className={styles.timeMenuItem}
                      onClick={() => { setHourValue(item) }}>
                      {item < 10 ? "0" + item : item}
                    </MenuItem>
                ))}
              </MenuList>
              <MenuList>
                {minuteOptions && minuteOptions.map((item, index) => (
                  (item === minuteValue) ?
                    <MenuItem key={`${item}-${index}-min`} dense
                      className={styles.selectedTimeItem}
                      sx={{ backgroundColor: themeColors.primary }}>
                      {item < 10 ? "0" + item : item}
                    </MenuItem>
                    :
                    <MenuItem key={item} dense
                      className={styles.timeMenuItem}
                      onClick={() => { setMinuteValue(item) }}>
                      {item < 10 ? "0" + item : item}
                    </MenuItem>
                ))}
              </MenuList>
            </div>
          </Paper>
        </ClickAwayListener>
      </Popover>
    </div >
  );
};

export default InputDateTime;
